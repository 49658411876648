import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      hasAsked: false,
      question: '', // Kullanıcının girdiği soru
      questionLimit: 0,
      isLoading: false, // İşlem sırasında butonu kilitlemek için
      ratings: {}, // Bölüm puanları
      views: {}, // Bölüm görüntülenme sayıları
      activeTab: 'Bölümler', // Aktif sekme
    };
  }

  componentDidMount() {
    this.checkIfAsked(); // Kullanıcının daha önce soru sorup sormadığını kontrol eder
    this.fetchRatings(); // Bölüm puanlarını alır
    this.fetchViews(); // Bölüm görüntülenme sayılarını alır
    this.fetchQuestions();
  }

  removeZero = (slug) => {
    return slug.replace(/-(0\d+)$/, (match, p1) => `-${p1.replace(/^0+/, '')}`);
  };

  // Kullanıcının IP'sine göre soru sorup sormadığını kontrol eden API
  checkIfAsked = async () => {
    try {
      const response = await fetch('https://darknight-api.vercel.app/question');
      const data = await response.json();
      this.setState({
        questionLimit: data.questionLimit, // Kalan soru hakkını ekliyoruz
      });
    } catch (error) {
      console.error('Soru kontrolünde hata:', error);
    }
  };

  // Bölüm puanlarını API'den alır
  fetchRatings = async () => {
    try {
      const markdownList = require('../markdownList.json'); // JSON dosyasını yükle
      const ratings = await Promise.all(
        markdownList.map(async (post) => {
          const response = await fetch(`https://darknight-api.vercel.app/rating?slug=${this.removeZero(post.slug)}`);
          const data = await response.json();
          return { slug: post.slug, rating: data.averageRating };
        })
      );
      this.setState({
        ratings: Object.fromEntries(ratings.map(({ slug, rating }) => [slug, rating])),
      });
    } catch (error) {
      console.error('Puanları alırken hata:', error);
    }
  };

  // Her bölüm için görüntülenme sayısını alır
  fetchViews = async () => {
    const markdownList = require('../markdownList.json'); // JSON dosyasını yükle

    try {
      const views = await Promise.all(
        markdownList.map(async (post) => {
          const response = await fetch(`https://darknight-api.vercel.app/views?slug=${this.removeZero(post.slug)}`);
          const data = await response.json();
          return { slug: post.slug, totalViews: data.totalViews };
        })
      );

      this.setState({
        views: Object.fromEntries(views.map(({ slug, totalViews }) => [slug, totalViews])),
      });
    } catch (error) {
      console.error('Görüntülenme sayısı alınırken hata:', error);
    }
  };

  // Kullanıcının sorusunu API'ye gönderir
  sendQuestion = async () => {
    const { question, questionLimit } = this.state;

    if (!question.trim()) return; // Soru boşsa işlem yapma
    if (questionLimit <= 0) {
      alert('Soru gönderme limitiniz doldu. Daha fazla soru gönderemezsiniz.');
      return;
    }

    this.setState({ isLoading: true });

    try {
      const response = await fetch('https://darknight-api.vercel.app/question', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question }),
      });

      const data = await response.json();
      if (data.success) {
        this.setState({
          hasAsked: true,
          question: '',
          questionLimit: questionLimit - 1 // Soruyu başarıyla gönderdiğimizde limitten 1 azaltıyoruz
        });
      }

      setTimeout(() => {
        this.setState({
          hasAsked: false,
        });
      }, 1500);
    } catch (error) {
      console.error('Soru gönderme hatası:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  fetchQuestions = async () => {
    try {
      const response = await fetch('https://darknight-api.vercel.app/questions'); // API URL
      const data = await response.json();

      if (data.success) {
        this.setState({ questions: data.questions });
      } else {
        console.error('Soruları almakta hata oluştu');
      }
    } catch (error) {
      console.error('API hatası:', error);
    }
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { questions, hasAsked, question, questionLimit, isLoading, ratings, views, activeTab } = this.state;
    const markdownList = require('../markdownList.json'); // JSON dosyasını yükle
    const sortedMarkdownList = [...markdownList].sort((a, b) => b.title.localeCompare(a.title, undefined, { numeric: true }));
    const tabs = ['Bölümler', 'Karakterler', 'Anketler'];

    return (
      <div className="homepage">
        <div className="blog-post">
          <h1 className="main-title">
            Üstün Zekalar: <a style={{ fontSize: 30, color: "#ff875b" }}>Kah'ın Gölgesinde</a>
          </h1>

          <div className="mini-nav">
            {tabs.map((tab) => (
              <a key={tab} onClick={() => this.changeTab(tab)}>
                <div
                  style={activeTab === tab ? { borderBottom: '3px solid #deb887' } : {}}
                  className={`button ${/*tab === "Anketler" ? "gold-blink" : ""*/""}`}
                >
                  {tab}
                </div>
              </a>
            ))}
          </div>

          {activeTab === 'Bölümler' && (
            <div>
              {/* // TODO - <div className="alert" style={{ borderImage: "linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet)", borderImageSlice: 1 }}>
                Karakterini oyla, anketler sayfasında.
              </div>*/}
              
              {<div className="alert">
                Sömestrden sonra kaldığı yerden devam edecek. Teşekkürler!
              </div>}

              {sortedMarkdownList.map(({ slug, title, summary }) => (
                <div className="post" key={slug}>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginBottom: 5 }}>
                      <span style={{ color: ratings[slug] ? "gold" : "#e0e0e0", fontSize: 20 }}>★ </span>
                      {ratings[slug] || "0.00"}
                    </div>

                    <div style={{ marginLeft: 10, marginBottom: 5 }}>
                      <span style={{ fontSize: 20 }}>{views[slug] ? "👁️" : "💀"}</span>
                      {views[slug] || 0}
                    </div>
                  </div>

                  <Link to={`/blog/${slug}`}>
                    <h2 className="post-title">{title}</h2>
                  </Link>

                  <p className="post-summary">{summary}</p>
                </div>
              ))}

              <div style={{ color: "#bbb" }}>— İki haftada bir yeni bölüm!!!</div>
            </div>
          )}

          {activeTab === 'Karakterler' && (
            <div>
              <h2>Yakında...</h2>
            </div>
          )}

          {activeTab === 'Anketler' && (
            <div>Çok yakında yenisi geliyor...212121</div>
          )}

          {/*activeTab === 'Anketler' && (
            <div className="tab">
              <h2>Soru Zamanı!</h2>
              <p>Hikayeyle ilgili sorularınızı 30 Kasım'a kadar gönderebilirsiniz.</p>

              <input
                placeholder={`Kalan ${questionLimit} Soru Hakkınız`}
                value={question}
                onChange={(e) => this.setState({ question: e.target.value })}
                disabled={hasAsked || questionLimit <= 0}
              />

              <button
                onClick={this.sendQuestion}
                disabled={hasAsked || isLoading || questionLimit <= 0}
              >
                {hasAsked ? 'Soru Gönderildi' : isLoading ? 'Gönderiliyor...' : `Gönder (${questionLimit})`}
              </button>

              <h3 style={{ marginTop: 50, marginBottom: 0 }}>Tüm Sorular</h3>
              {questions.length === 0 ? (
                <p style={{ color: "#aaa" }}>Henüz soru gönderilmedi.</p>
              ) : (
                <ul>
                  {questions.map((question, index) => (
                    <li key={index}>
                      <p style={{ color: "#aaa" }}>{question.question_text}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )*/}
        </div>

        <style>{`
        .alert {
          width: 100%;
          font-weight: 700;
          margin: 10px auto;
          padding: 10px 8px;
          border: 3px solid gold;
          border-image-slice: 1;
          border-radius: 10px;
          line-height: 1.5;
          box-sizing: border-box;
        }

        .mini-nav {
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
        }

        .mini-nav a {
          color: #e0e0e0;
          text-decoration: none !important;
        }

        .mini-nav .button {
          padding: 4px 12px;
          border-bottom: 3px solid white;
          transition: .2s;
          cursor: pointer;
        }

        .main-title {
          font-size: 42px !important;
          margin: 40px 0 90px !important;
          text-align: left;
          line-height: 1 !important;
        }

        .tab input {
          width: 100%;
          padding: 10px;
          border: 1px solid #444;
          border-radius: 5px;
          background-color: #222;
          color: #fff;
          font-size: 14px;
          outline: none;
          transition: border-color 0.3s ease;
          box-sizing: border-box;
        }
        
        .tab input:focus {
          border-color: #1e90ff;
        }

        .tab input:disabled {
          filter: grayscale(100%); /* Grileştir */
          opacity: 0.6; /* Hafif şeffaflık */
          cursor: not-allowed; /* İşaretçi şekli */
        }

        .tab button {
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          background-color: #1e90ff;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
          margin-left: auto;
          transition: background-color 0.2s ease;
          display: block;
        }
        
        .tab button:hover {
          background-color: #559eff;
        }

        .tab button:disabled {
          filter: grayscale(100%); /* Grileştir */
          opacity: 0.6; /* Hafif şeffaflık */
          cursor: not-allowed; /* İşaretçi şekli */
        }

        h2 {
          line-height: normal;
          margin: 0 0 0 !important;
        }

        a {
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        p {
          margin: 0 0 40px !important;
          text-align: justify;
        }

        @keyframes blinkGold {
          0%, 100% { color: #e0e0e0; text-shadow: 0 0 5px #e0e0e0; }
          50% { color: gold; text-shadow: 0 0 10px gold; }
        }

        .gold-blink {
          animation: blinkGold 1.5s infinite;
        }
      `}</style>
      </div>
    );
  }
}

export default HomePage;
